<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="">
          <div class="mainList_operation_search">
            <div class="search_center">
              <span>填报月份：</span>
              <el-date-picker
                v-model="parameter.monthYear"
                @change="dataUpdate"
                :picker-options="pickerOptions"
                type="month"
                placeholder="选择月"
                value-format="yyyy-MM"
                class="ipt_width">
              </el-date-picker>
            </div>
            <div class="search_center">
              <span>项目名称：</span>
              <el-select
                v-model="parameter.projectId"
                @change="dataUpdate"
                clearable
                filterable
                placeholder="请输入或选择项目名称"
                class="ipt_width project_width">
                <el-option
                  v-for="item in projectList"
                  :key="item.id"
                  :label="item.projectName"
                  :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="search_center">
              <span>项目经理：</span>
              <el-select
                v-model="parameter.createBy"
                @change="dataUpdate"
                clearable
                filterable
                placeholder="请选择项目经理"
                class="ipt_width">
                <el-option
                  v-for="item in projectManagerList"
                  :key="item.id"
                  :label="item.userName"
                  :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="search_center">
              <span>当前状态：</span>
              <Dictionary
                v-model="parameter.status"
                class="ipt_width"
                code="PERFORMANCE_HOUR_LIST_STATUS"
                placeholder="请选择填报状态" />
            </div>
            <el-button type="primary" icon="el-icon-search" @click="getData">搜索</el-button>
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
      </div>

      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            height="string"
            v-loading="loading">
            <el-table-column align="center" type="index" label="序号"></el-table-column>
            <el-table-column
              align="center"
              prop="projectName"
              label="项目名称"
              :show-overflow-tooltip="false"></el-table-column>
            <!-- 项目经理 -->
            <el-table-column
              align="center"
              prop="createName"
              label="项目经理"
              width="200px"
              :show-overflow-tooltip="false">
            </el-table-column>
            <!-- 填报状态 -->
            <el-table-column
              align="center"
              prop="statusName"
              label="当前状态"
              width="200px"
              :show-overflow-tooltip="false">
            </el-table-column>
            <!-- 填报时间 -->
            <el-table-column
              align="center"
              prop="taskDate"
              label="提交时间"
              width="200px"
              :show-overflow-tooltip="false">
              <template slot-scope="scope">
                {{
                  scope.row.taskDate
                    ? new Date(scope.row.taskDate).format('yyyy-MM-dd hh:mm:ss')
                    : ''
                }}
              </template>
            </el-table-column>
            <!-- 操作 -->
            <el-table-column prop="title" align="center" fixed="right" label="操作" width="200">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  class="text_Details_Bgc"
                  @click.stop="detail(scope.row)"
                  v-if="scope.row.id"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total">
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <Edit v-if="showEditDialog" :isShow.sync="showEditDialog" :options="form"></Edit>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    Edit: () => import('../Edit/edit.vue'),
  },
  provide() {
    return {
      re: this.re,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  data() {
    return {
      parameter: {
        pageNow: 1,
        pageSize: 50,
        monthYear: '', //填报月份
        projectId: '', //项目Id
        status: '', //填报状态
        total: 0,
      },
      pageSize: 0,
      showEditDialog: false,
      form: {}, //点击填报传参对象
      tableData: [],
      loading: false,
      // 时间选择器详细配置项
      pickerOptions: {
        // 禁用时间方法
        disabledDate(time) {
          let date = new Date()
          let year = date.getFullYear()
          let month = date.getMonth()
          if (month == 0) {
            month = 12
            year = year - 1
          }
          if (month < 10) {
            month = '0' + month
          }
          return time.getTime() > new Date(year + '-' + month).getTime()
        },
      },

      projectList: [], // 项目列表数据
      projectManagerList: [], //项目经理列表
    }
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.parameter.monthYear = this.getDefaultMonth()
    this.getProjectList() /** 加载项目列表数据 **/
    this.getProjectManagerList()
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      this.$api.hour
        .getManhourPerformanceApprovalList(this.parameter)
        .then(res => {
          this.loading = false
          if (res.data == []) {
            this.tableData = res.data
          } else {
            this.tableData = res.data ? (res.data.records ? res.data.records : []) : []

            this.parameter.total = res.data ? (res.data.total ? res.data.total : 0) : 0
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    // 获取默认填报月份（上个月）
    getDefaultMonth() {
      const date = new Date()
      let year = date.getFullYear()
      // 获取当前月份(date.getMonth()返回的是0-11)
      let month = date.getMonth()
      if (month == 0) {
        month = 12
        year = year - 1
      }
      if (month < 10) {
        month = '0' + month
      }
      return year + '-' + month
    },

    // 加载项目列表数据
    getProjectList() {
      this.$api.project
        .getProjectList()
        .then(res => {
          this.projectList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    // 加载项目列表数据
    getProjectManagerList() {
      this.$api.project
        .getProjectManagerList()
        .then(res => {
          this.projectManagerList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    //点击重置
    handleReset() {
      this.parameter.projectId = ''
      this.parameter.status = ''
      this.parameter.monthYear = this.getDefaultMonth()
      this.getData()
    },

    dataUpdate() {
      this.$forceUpdate()
    },

    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },

    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },

    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }

      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },

    re() {
      this.form = {}
      this.getData()
      this.showEditDialog = false
    },

    // 点击填报
    detail(value) {
      this.form = {
        list_s: 3,
        monthYear: this.parameter.monthYear,
        ...value,
      }
      this.showEditDialog = true
    },
  },
}
</script>

<style></style>
